import React from 'react';
import styled from 'styled-components';
import { SEO, Layout, ContentBody, ContentAside } from 'components';
import { graphql, Link } from 'gatsby';
import Img from 'gatsby-image';
// import { useWindowSize } from 'hooks';
// import { useLocation } from '@reach/router';
// import PrevArrow from 'images/shared/PrevArrow';
import BlockContent from '@sanity/block-content-to-react';
import imageUrlBuilder from '@sanity/image-url';

const builder = imageUrlBuilder({
  projectId: 'wtrhcvep',
  dataset: 'production'
});

function urlFor(source) {
  return builder.image(source);
}

export default ({ data, location }) => {
  const { blogPost, recentBlogPosts } = data;
  const { title, summary, mainImage, slug, author, publishedAt, _rawBody } = blogPost;
  const objectPosition = mainImage?.image?.hotspot && {
    x: `${mainImage.image.hotspot.x * 100}%`,
    y: `${mainImage.image.hotspot.y * 100}%`
  };

  return (
    <Layout>
      <SEO
        pageSpecificTitle={title}
        pageSpecificDescription={summary}
        pageSpecificThumbnail={urlFor(mainImage?.image)
          .width(1200)
          .url()}
        pageSpecificThumbnailAlt={mainImage.alt}
        pathname={location.pathname}
      />
      <HeroWrapper>
        {mainImage.image &&
          (objectPosition ? (
            <HeroImageWrapper objectPosition={objectPosition}>
              <HeroImg
                fluid={mainImage.image.asset.fluid}
                style={{ width: '100%', height: 600 }}
                alt={mainImage.image.alt}
              />
            </HeroImageWrapper>
          ) : (
            <HeroImg
              fluid={mainImage.image.asset.fluid}
              style={{ width: '100%', height: 600 }}
              alt={mainImage.image.alt}
            />
          ))}
        <HeroText>
          <h3>
            {publishedAt}
            <MultiColouredBottomBorder />
          </h3>
          <h1 dangerouslySetInnerHTML={{ __html: title }} />
        </HeroText>
      </HeroWrapper>
      <ContentWrapper>
        <ContentBody blockContent={_rawBody} slug="" />
        {recentBlogPosts.nodes.length && (
          <ContentAside recentBlogPosts={recentBlogPosts.nodes} slug={slug.current} />
        )}
      </ContentWrapper>
    </Layout>
  );
};

const HeroWrapper = styled.div`
  position: relative;
`;

const HeroImageWrapper = styled.div`
  .gatsby-image-wrapper {
    div[aria-hidden='true'] {
      padding-bottom: 41% !important;
    }

    img {
      object-position: ${(props) =>
        `${props.objectPosition.x} ${props.objectPosition.x}`} !important;
    }
  }
`;

const HeroText = styled.div`
  position: absolute;
  color: #fff;
  bottom: 3.05rem;
  max-width: 73.5rem;
  left: 12px;
  margin-right: 12px;

  @media screen and (min-width: 420px) {
    left: 24px;
    margin-right: 24px;
    bottom: 5.625rem;
  }

  h1 {
    font-size: 3rem;
    font-weight: 400;

    @media screen and (min-width: 820px) {
      font-size: 4rem;
    }
  }

  h3 {
    font-size: 1.125rem;
    text-transform: uppercase;
    font-weight: 700;
    display: inline-block;
  }

  @media screen and (min-width: 1176px) {
    left: 9%;
  }

  @media screen and (min-width: 1440px) {
    left: 8.125rem;
  }
`;

const MultiColouredBottomBorder = styled.div`
  background: linear-gradient(269.94deg, #e4097e 63.91%, #ffef00 100%);
  height: 0.188rem;
  margin: 0.063rem 0 0.75rem;
  width: calc(100% + 1rem);
`;

const HeroImg = styled(Img)`
  position: absolute;
  top: 0;
  left: 0;
`;

const ContentWrapper = styled.div`
  display: flex;
  flex-direction: column;
  padding: 0 12px;
  max-width: 1176px;

  @media screen and (min-width: 420px) {
    padding: 0 24px;
  }

  @media screen and (min-width: 1200px) {
    flex-direction: row;
    justify-content: space-between;
    padding: 0 12px;
    margin: 0 auto;
  }

  @media screen and (min-width: 1224px) {
    padding: 0;
  }
`;

export const query = graphql`
  query($slug: String!) {
    blogPost: sanityBlogPost(slug: { current: { eq: $slug } }) {
      title
      summary
      slug {
        current
      }
      mainImage {
        image {
          hotspot {
            x
            y
          }
          asset {
            url
            fluid(maxWidth: 1440, maxHeight: 600) {
              ...GatsbySanityImageFluid
            }
          }
        }
        title
        alt
      }
      author {
        name
      }
      publishedAt(formatString: "Do MMMM YYYY")
      _rawBody
    }
    recentBlogPosts: allSanityBlogPost(
      filter: { publishedAt: { ne: null }, slug: { current: { ne: $slug } } }
      sort: { fields: [publishedAt], order: [DESC] }
      limit: 2
    ) {
      nodes {
        id
        title
        author {
          name
        }
        mainImage {
          image {
            asset {
              fixed(width: 325, height: 198) {
                ...GatsbySanityImageFixed
              }
            }
          }
          title
          alt
        }
        publishedAt(formatString: "Do MMMM YYYY")
        summary
        slug {
          current
        }
      }
    }
  }
`;
